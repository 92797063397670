<template>
  <div class="skeleton-bonuses">
    <Skeletor class="skeleton-bonuses__title" as="div" />

    <Skeletor class="skeleton-bonuses__summary-info" as="div" />

    <div class="skeleton-bonuses__content">

      <Skeletor class="skeleton-bonuses__tabs" as="div" />

      <div class="skeleton-bonuses__active-bonuses">
        <Skeletor
          v-for="n in ACIVE_BONUSES_COUNT"
          :key="n"
          class="skeleton-bonuses__active-bonus"
          as="div" />
      </div>

      <Skeletor class="skeleton-bonuses__title" as="div" />

      <Skeletor class="skeleton-bonuses__text" as="div" />

      <Skeletor class="skeleton-bonuses__items-title" as="div" />

      <Skeletor class="skeleton-bonuses__steps" as="div" />

      <div class="skeleton-bonuses__items">
        <Skeletor
          v-for="n in BONUSES_ITEMS_COUNT"
          :key="n"
          class="skeleton-bonuses__item"
          as="div" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const ACIVE_BONUSES_COUNT = 3;
  const BONUSES_ITEMS_COUNT = 4;

</script>

<style src="~/assets/styles/components/skeleton-preloader/bonuses-page.scss" lang="scss" />
